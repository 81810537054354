import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import {
  useCarValues,
  useEvaluationRedemptionData,
  useValueCarOptions,
} from '@/modules/EvaluationRedemption/hooks';
import { media } from '@/utils/mixin';
import {
  ContentContainer,
  Title,
} from '@/modules/EvaluationRedemption/common.styles';
import { useTypedSelector } from '@/store/store';
import { selectRateCarsData } from '@/store/reducers/rate-car-data';
import { CarSelectsValues } from '@/modules/EvaluationRedemption/types';
import { OptionType } from '@/typings/common';
import EvaluationRedemptionModal from '@/components/modals/EvaluationRedemptionModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitRateCarOffline } from '@/services/leads/leads-service';

import Form from './components/Form';

function HeroForm() {
  const {
    templateFields: { formTitle, formButtonText },
  } = useEvaluationRedemptionData();
  const [values, setValues] = useCarValues();
  const options = useValueCarOptions(values.brand);
  const data = useTypedSelector(selectRateCarsData);
  const modal = useModal();

  if (!formTitle || !formButtonText) {
    return null;
  }

  const city = data.dealers?.[0].city.name;

  const citiesList: OptionType[] =
    data.dealers?.[0].locations.map((item) => ({
      value: `г.${city}, ${item.address}`,
      label: `г.${city}, ${item.address}`,
    })) ?? [];

  const onSubmit = (values: CarSelectsValues) => {
    modal(
      EvaluationRedemptionModal,
      {
        title: 'Оценка и выкуп',
        selectedFields: {
          brand: values.brand?.value ?? '',
          model: values.model?.value ?? '',
          year: Number(values.year?.value) ?? 0,
        },
        locations: citiesList,
        onSubmit: async (values) => {
          try {
            await submitRateCarOffline({
              address: values.location.value,
              brand: values.brand,
              model: values.model,
              year: values.year,
              datetime: values.date + ' ' + values.time,
              name: values.name,
              phone: values.phone,
              email: values.email,
            });
          } catch (error: any) {
            console.log(error);
          }
        },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <Title>{formTitle}</Title>

        <FormWrapper>
          <Form
            onSubmit={onSubmit}
            values={values}
            onChange={setValues}
            options={options}
            buttonText={formButtonText}
          />
        </FormWrapper>
      </ContentContainer>
    </Component>
  );
}

export default HeroForm;

const Component = styled.div`
  margin: 5px 0 40px;

  ${media.tablet(css`
    margin: 5px 0 0;
  `)}
`;

const FormWrapper = styled.div`
  margin-top: 10px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;
