import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';
import moment from 'moment';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { CheckboxAgreement } from '@/components/Checkbox';
import Loader from '@/components/Loader';
import CloseButton from '@/components/modals/shared/CloseButton';
import SuccessForm from '@/components/Forms/SuccessForm';
import TextInput from '@/components/TextInput';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import Datapicker from '@/components/Datapicker';
import { getTomorrowDate } from '@/utils/date';
import Timepicker from '@/components/Timepicker';
import Select from '@/components/Select';
import { OptionType } from '@/typings/common';

import {
  getInitialEvaluationRedemptionModalValues,
  validationEvaluationRedemptionModalSchema,
} from './helpers';
import {
  EvaluationRedemptionModalFields,
  EvaluationRedemptionProps,
} from './types';

function EvaluationRedemptionModal({
  closeModal,
  innerProps,
}: EvaluationRedemptionProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [location, setLocation] = useState<OptionType>({
    value: '',
    label: '',
  });
  const { title, onSubmit, selectedFields, locations } = innerProps;

  useUpdateEffect(() => {
    setFieldValue('location', location);
  }, [location]);

  useLockBodyScroll();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    isSubmitting,
  } = useFormik({
    initialValues: getInitialEvaluationRedemptionModalValues(selectedFields),
    validationSchema: validationEvaluationRedemptionModalSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => setFormSend(true));
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<EvaluationRedemptionModalFields>;
        setErrors(errorMap);
        console.error(error);
      }
    },
  });

  return (
    <Component
      isFormSend={isFormSend}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <CloseButton onClick={closeModal} />

      {!isFormSend ? (
        <Content>
          <Title>{title}</Title>
          <InputsContainer>
            <InputRow>
              <TextInput
                id="brand"
                name="brand"
                value={selectedFields.brand}
                disabled
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="model"
                name="model"
                value={selectedFields.model}
                disabled
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="year"
                name="year"
                value={selectedFields.year}
                disabled
              />
            </InputRow>

            <InputRow>
              <Select
                label="Автоцентр"
                value={location}
                options={locations}
                onChange={setLocation}
                errorMessage={
                  errors.location?.value && touched.location?.value
                    ? errors.location.value
                    : undefined
                }
              />
            </InputRow>

            <InputRow>
              <Datapicker
                id="date"
                name="date"
                label="Дата"
                selected={values.date ? new Date(values.date) : null}
                onChange={(date) =>
                  setFieldValue(
                    'date',
                    moment(date as Date | null).format('YYYY-MM-DD')
                  )
                }
                locale="ru"
                dateFormat={['dd.MM.yyyy']}
                minDate={getTomorrowDate()}
                fixedHeight
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
                errorMessage={
                  errors.date && touched.date ? errors.date : undefined
                }
              />
            </InputRow>

            <InputRow>
              <Timepicker
                selectedTime={values.time}
                disabled={!values.date}
                disabledMessage="Сначала выберите дату"
                onClick={(time) => setFieldValue('time', time)}
                errorMessage={
                  errors.time && touched.time ? errors.time : undefined
                }
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="name"
                name="name"
                label="Имя"
                placeholder="Имя"
                value={values.name}
                onChange={handleChange}
                errorMessage={
                  errors.name && touched.name ? errors.name : undefined
                }
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="phone"
                name="phone"
                label="Телефон"
                placeholder="+375 (__) ___-__-__"
                value={values.phone}
                onChange={handleChange}
                errorMessage={
                  errors.phone && touched.phone ? errors.phone : undefined
                }
              />
            </InputRow>

            <InputRow>
              <TextInput
                id="email"
                name="email"
                label="Email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                errorMessage={
                  errors.email && touched.email ? errors.email : undefined
                }
              />
            </InputRow>

            <ButtonWrapper>
              <StyledButton
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                {!isSubmitting ? 'Отправить' : <Loader />}
              </StyledButton>
            </ButtonWrapper>

            <CheckboxAgreementWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxAgreementWrapper>
          </InputsContainer>
        </Content>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default EvaluationRedemptionModal;

const Component = styled.form<{ isFormSend?: boolean }>`
  display: flex;
  justify-content: center;
  min-width: 674px;
  padding: 25px 40px;
  border-radius: 10px;
  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    min-width: auto;
    padding: 20px;
  `)}

  ${({ isFormSend }) =>
    isFormSend &&
    css`
      ${media.mobile(css`
        align-items: center;
      `)}
    `}
`;

const Content = styled.div`
  max-width: 332px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 30px;
  `)};
`;

const InputRow = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-top: 30px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

const StyledButton = styled(Button)<{ isWhiteBackground?: boolean }>`
  position: relative;
  width: 100%;
  height: 48px;
  padding: 14px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-radius: 14px;
  background: ${colors.main};
  color: ${colors.white};
  transition: 0.3s;

  &:disabled {
    color: ${(props) => props.theme.black100};
    background: ${(props) => props.theme.gray400_1};
    border: 1px solid ${(props) => props.theme.gray400_2};
    opacity: 0.35;

    &:hover {
      background: ${(props) => props.theme.gray400_1};
    }
  }

  &:hover {
    background: ${colors.main100};
  }
`;

const CheckboxAgreementWrapper = styled.div`
  margin-top: 14px;
`;
