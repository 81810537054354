import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { useJetourHomeData } from '@/modules/JetourHome/hooks';
import { BannersSliderPrimary } from '@/components/LandingWidgets';
import { Container as BannersSliderPrimaryContainer } from '@/components/LandingWidgets/components/BannersSliderPrimary';
import { media } from '@/utils/mixin';
import { getDealersOptions, getSelectOptions } from '@/utils/common';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitCarModel } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function HeroSlider() {
  const openModal = useModal();
  const {
    heroBanners,
    dealersRequests,
    modelsRequests,
    heroBannersFormIsShowModel,
    heroBannersFormIsShowDealer,
  } = useJetourHomeData();

  if (!heroBanners || heroBanners.length === 0) {
    return null;
  }

  const fieldsConfig = {
    withMessage: true,
    models:
      !heroBannersFormIsShowModel && modelsRequests
        ? getSelectOptions(modelsRequests)
        : undefined,
    dealers:
      !heroBannersFormIsShowDealer && dealersRequests
        ? getDealersOptions(dealersRequests)
        : undefined,
  };

  const sendCarModelForm = async (
    values: ApplicationFormFields,
    event: string
  ): Promise<void> => {
    try {
      await submitCarModel({
        ...values,
        dealer: values.dealer?.label,
        model: values.model?.value || 'Jetour',
      }).then(() => {
        if (event) {
          googleEvent(event, {
            car: values.model?.value,
          });
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  function handleOpenModal(event: string, formTitle?: string) {
    openModal(
      ApplicationFormModal,
      {
        onSubmit: (values) => sendCarModelForm(values, event),
        title: formTitle,
        fieldsConfig,
      },
      {
        components: { Overlay: StyledOverlay },
        scrollLockDisabled: true,
      }
    );
  }

  return (
    <Component>
      <BannersSliderPrimary
        id={1}
        banners={heroBanners.map(
          ({
            image,
            mobileImage,
            text,
            url,
            firstButtonText,
            firstButtonLink,
            secondButtonText,
            formTitle,
            formGoogleEvent,
            video,
          }) => ({
            image,
            mobileImage,
            firstButtonText: firstButtonText ?? '',
            firstButtonLink: firstButtonLink ?? '',
            secondButtonText: secondButtonText ?? '',
            secondButtonOnClick: () =>
              handleOpenModal(formGoogleEvent ?? '', formTitle ?? ''),
            text: text ?? '',
            url: url ?? '',
            video: video?.url ?? '',
          })
        )}
      />
    </Component>
  );
}

export default HeroSlider;

const Component = styled.section`
  ${BannersSliderPrimaryContainer} {
    padding-top: 51.283%;

    ${media.tablet(css`
      padding-top: initial;
      height: 800px;
    `)}
  }

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
