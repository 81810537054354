import * as Yup from 'yup';

import { emailRegexp } from '@/utils/textInput';

import { EvaluationRedemptionModalFields, SelectedFieldsValues } from './types';

export const getInitialEvaluationRedemptionModalValues = ({
  brand,
  model,
  year,
}: SelectedFieldsValues): EvaluationRedemptionModalFields => ({
  brand,
  model,
  year,
  location: { value: '', label: '' },
  date: '',
  time: '',
  name: '',
  phone: '',
  email: '',
});

export const validationEvaluationRedemptionModalSchema = Yup.object().shape({
  name: Yup.string().required('Введите имя'),
  phone: Yup.string().required('Введите телефон'),
  location: Yup.object()
    .shape({
      value: Yup.string().required('Выберите автосалон'),
      label: Yup.string().required('Выберите автосалон'),
    })
    .required('Выберите автосалон'),
  email: Yup.string()
    .required('Введите Email')
    .matches(emailRegexp, 'Email введен неверно'),
  date: Yup.date().required('Выберите дату').nullable(),
  time: Yup.string().required('Выберите время'),
});
