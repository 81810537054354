import React from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { ServiceFormSecondary } from '@/components/LandingWidgets';
import { ServiceFormSecondaryFields } from '@/components/LandingWidgets/components/ServiceFormSecondary/types';
import { submitCarModel } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { getDealersOptions, getSelectOptions } from '@/utils/common';

import { useJetourHomeData } from '../../hooks';

function TestDriveForm() {
  const {
    formTitle,
    formSubtitle,
    formImage,
    formMobileImage,
    formButtonTitle,
    formGoogleEvent,
    dealersRequests,
    modelsRequests,
    formDealersCheckbox: isShowDealers,
    formModelsCheckbox: isShowModels,
  } = useJetourHomeData();

  if (!formTitle || !formImage) {
    return null;
  }

  const fieldsConfig = {
    models: isShowModels ? getSelectOptions(modelsRequests) : undefined,
    dealers: isShowDealers ? getDealersOptions(dealersRequests) : undefined,
  };

  const sendTestDriveForm = async (
    values: ServiceFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitCarModel({
        ...values,
        dealer: values.dealer?.label,
        model: values.model?.value || 'Jetour',
      }).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent, {
            car: values.model?.value,
          });
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component id="test-drive">
      <ContentContainer>
        <ServiceFormSecondary
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          image={formImage}
          buttonTitle={formButtonTitle ?? ''}
          imageMobile={formMobileImage}
          fieldsConfig={fieldsConfig}
          onSubmit={sendTestDriveForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default TestDriveForm;

const Component = styled.section`
  padding: 30px 0 60px;
`;
