import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/ServiceBenefits/common.styles';
import { useServiceBenefitsData } from '@/modules/ServiceBenefits/hooks';
import { media } from '@/utils/mixin';
import ConsultationFormSecondary from '@/components/Forms/ConsultationFormSecondary';
import { useTypedSelector } from '@/store/store';
import { getServiceBrands, getServiceDealers } from '@/store/reducers/service';
import { getServicePageOptions } from '@/modules/Service/common.helpers';
import {
  submitServiceBenefits,
  submitServiceRequestBodyRepair,
} from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { ConsultationFormSecondaryFields } from '@/components/Forms/ConsultationFormSecondary/types';

function Form() {
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);
  const {
    formImage,
    formImageMobile,
    formTitle,
    formButtonText,
    formBrandDealerHidden,
    formGoogleEvent,
    formSubmitBodyRepair,
  } = useServiceBenefitsData();

  if (!formImage || !formTitle || !formButtonText) {
    return null;
  }

  const { brands, dealers } = getServicePageOptions(
    serviceDealers,
    serviceBrands
  );

  const handleSubmitServiceBenefitsForm = async (
    values: ConsultationFormSecondaryFields
  ): Promise<void> => {
    try {
      const fields = {
        brand: values.brand?.value ?? undefined,
        dealer: Number(values.dealer?.value) ?? undefined,
      };

      if (formSubmitBodyRepair) {
        const { message, ...otherValues } = values;

        await submitServiceRequestBodyRepair({
          ...otherValues,
          ...fields,
          comment: message,
        });
      } else {
        await submitServiceBenefits({
          ...values,
          ...fields,
        });
      }

      if (formGoogleEvent) {
        googleEvent(formGoogleEvent);
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ConsultationFormSecondary
          image={formImage}
          imageMobile={formImageMobile}
          title={formTitle ?? ''}
          buttonText={formButtonText ?? ''}
          brands={brands}
          dealers={dealers}
          fieldsConfig={{
            withBrands: !formBrandDealerHidden,
            withDealers: !formBrandDealerHidden,
            withMessage: true,
          }}
          onSubmit={handleSubmitServiceBenefitsForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}
`;
